html,
body {
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  font-family: Raleway, sans-serif;
}

body {
  max-width: 100%;
  height: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid rgb(199, 34, 141); */
}

header {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid rgb(69, 208, 247); */
}

.hero-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  position: relative;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid darkorange; */
}

.main-image {
  width: 100%;
  height: auto;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid rgb(177, 20, 143); */
}

.logo {
  width: 25%;
  margin-bottom: 2rem;
  z-index: 0;

  /* border: 1px solid rgb(90, 255, 65); */
}

.modal {
  display: none;
}

/* .modal-section {
  display: flex;
  flex-flow: column;
  align-items: center;
} */

.modal-reveal {
  display: none;
  /* flex-flow: column;
  align-items: center;
  justify-content: center;

  width: 80vw;
  height: 80vh;
  position: fixed;
  margin-top: 60px;
  font-size: 2rem;
  background-image: url(../imgs/main2_compr.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  z-index: 10;

  transition: all;
  transition-duration: 500ms;
  transition-timing-function: ease-in;

  ~~~~ FOR DEBUGGING~~~~
  border: 3px solid red; */
}
/* 
.subscribenow-text {
  font-size: 2rem;
  background-color: rgba(255, 255, 255, 0.662);
} */

/* .subscribe-details {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  margin-top: 30px;
  margin-bottom: 30px;
  height: 20rem;

  ~~~~ FOR DEBUGGING~~~~
  border: 3px solid green;
} */

/* .subscribe-label {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-right: 20px;
  font-size: 1.5rem;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.662);
} */

/* #subscribe-email {
  border: solid;
  margin-right: 20px;
  width: 15rem;
  height: 1.5rem;
} */

/* #submit-subscribe {
  width: 5rem;
  height: 2rem;
  border: none;
} */

/* #subscribe-image {
  width: 40vw;
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
} */

/* .overlay-reveal {
  width: 100%;
  height: 100%;
  background-color: rgba(253, 251, 251, 0.8);
  position: fixed;
  top: 0px;
  opacity: 1;

  transition: all;
  transition-duration: 500ms;
  transition-timing-function: ease-in;

  z-index: 9;

  ~~~~ FOR DEBUGGING~~~~
  border: 3px solid green;
} */

/* .close-modal,
.close-thanks {
  color: black;
  height: 20px;
  width: 20px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  position: absolute;
  right: -1rem;
  top: -2rem;
  border: none;
} */

/* h1 {
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 20px 0px;
  width: 95%;
  font-size: 2.5rem;

  ~~~~ FOR DEBUGGING~~~~
  border: 3px solid greenyellow;
} */

h3 {
  position: absolute;
  bottom: -3%;
  left: 55%;
  width: 40%;
  height: 20%;
  font-size: clamp(1.2rem, 6vw, 4rem);
  font-variant: small-caps;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.836);

  /* border: 1px solid darkblue; */
}

h3 span {
  font-weight: 700;
  padding: 5px;
  border-radius: 5px;
  color: #b1275f;
  color: #5eb6ae;
}

.thanks {
  display: none;
  /* flex-flow: column wrap;
  align-items: center;

  width: 80vw;
  height: 30vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 20%;
  margin-top: 60px;
  padding-top: 10px;
  font-size: 2rem;
  text-align: center;

  z-index: 10;

  ~~~~ FOR DEBUGGING~~~~
  border: 3px solid red; */
}

.hide {
  display: none;
}

p {
  font-size: 1.3rem;
}

/* ====MENU SETTINGS==== */

.menu {
  display: flex;
  flex-flow: column;
  width: 165px;
  height: 305px;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: ease;
  position: absolute;
  top: 10px;
  left: -200px;
  z-index: 10;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(20, 177, 35); */
}

.menu__ul {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  max-width: 115%;
  width: 160px;
  height: 300px;
  position: relative;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(228, 152, 37); */
}

.main-nav-tab {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  border-bottom: 1px solid;
  background-color: rgba(255, 255, 255, 0.808);

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(46, 37, 228); */
}
.contact {
  border-bottom: none;
}
.main-nav-tab:hover {
  background-color: rgba(255, 255, 255, 0.925);
  cursor: pointer;
}

.attrib {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  justify-content: center;

  width: 100%;
  color: black;
  text-decoration: none;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(40, 228, 37); */
}
.attrib-heb {
  font-size: 1.4rem;
  font-family: 'Amatic SC', sans-serif;
}
.categories-list {
  position: absolute;
  left: -200px;
  top: 60px;
  list-style: none;

  width: 165px;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: ease;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid darkblue */
}

.category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #e1dcc1c7;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid yellowgreen; */
}

.category-item:hover {
  background-color: rgba(202, 173, 183, 0.813);
}

.reveal {
  display: flex;
  flex-flow: column;
  text-align: center;
  left: 160px;
}

.category-text {
  display: flex;
  justify-content: center;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 2px solid yellowgreen; */
}

.icons-container {
  display: flex;
  flex-flow: row-reverse;
  justify-content: end;
  align-items: center;
  height: 4rem;
  width: 100%;
  padding-right: 0.8rem;
  z-index: 10;
  background-color: white;

  /* border: 2px solid darkcyan; */
}

.menubars-svg {
  display: flex;

  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  padding: 2px;
  margin-left: 1.5rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid lightcoral; */
}
.shoppingcart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 10;
  /* border: 2px solid blue; */
}

.shoppingcart-mobile {
  fill: rgb(0, 0, 0);
  width: 30px;
  height: 30px;
  padding: 1px;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 5px;
}
.cart-number {
  display: none;
}

.cart-number-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  position: absolute;
  left: 55%;
  top: 35%;
  background-color: rgba(255, 255, 255);
  width: 10px;
  height: 10px;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  z-index: 10;
}

.toggle-svg {
  display: none;
}

#menubars-svg {
  fill: rgb(0, 0, 0);
}

.shoppingcart-svg {
  display: none;
}

#login-tab {
  border-bottom: none;
}

#show-menu-button {
  display: none;
}

.languages-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  /* height: 80px; */
  border-top: 1px solid;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.808);

  /* border: 2px solid red; */
}

.languages-container button {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0px 0px 10px 5px rgba(128, 128, 128, 0.6);
  border-radius: 5px;
  font-size: 1rem;
  height: 1.6rem;
  width: 2.5rem;
}

/* ===== END OF MENU SETTINGS==== */

main {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: 100%; */
  top: 25%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid rgb(229, 247, 89); */
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ----------- Slider ---------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.slider-container {
  width: 90%;
  margin-top: 9px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(0, 217, 255); */
}

.slider-wrapper .img-list .slider-image-item {
  height: 100%;
  object-fit: cover;
}

.slider-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;

  overflow-x: hidden;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(179, 0, 255); */
}

.img-list {
  display: flex;
  flex-flow: row;
  width: 100%;
  position: relative;
  /* height: 300px; */
}

.slider-image-item {
  padding: 5px;
  width: calc(100% / 3);
  width: 100%;
  /* height: 100%; */
  transition: transform 1s;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid red; */
}

.slider_btns {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2%;
  height: 8%;
  width: 8%;
  background-color: rgba(255, 250, 218, 0.6);
  border: 0.5px solid rgba(198, 170, 118, 0.477);
  cursor: pointer;
  font-size: 2rem;
  color: rgb(108, 108, 108);
  text-align: center;
}

.slider-btn--left {
  left: 8%;
}

.slider-btn--right {
  right: 8%;
}

.slider_btns:hover {
  background-color: rgba(232, 232, 232, 0.6);
}

/* ----------- Slider end ----------------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.categories-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
  margin-top: 50px;
  padding-top: 2rem;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.58);

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(0, 255, 187); */
}

.category-box {
  width: 90%;
  height: 20%;
  text-decoration: none;
  position: relative;
  margin-bottom: 2.5rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid red; */
}

.large-img-item {
  width: 100%;
  height: auto;
}

.category-name {
  color: black;
  background-color: rgba(255, 255, 255, 0.888);
  position: absolute;
  bottom: 10%;
  left: 5%;
  font-size: clamp(2rem, 2vh, 2rem);
  padding: 5px;
}

.large-img-item.hidden {
  width: 100%;
  margin: 10px;
  opacity: 0;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(4, 255, 0); */
}

.go-to-top {
  position: relative;
  border: 1px solid;
  width: 40%;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5px;
  margin: 30px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(4, 255, 0); */
}

.go-to-top {
  align-self: center;

  width: 3rem;
  height: 3rem;
  margin: 4rem;
  font-size: 2rem;
  border: none;
  border-radius: 50%;
  /* background-image: linear-gradient(
    rgba(255, 166, 0, 0.653),
    rgba(255, 0, 0, 0.553)
  ); */
  background-color: black;
  padding: 5px;
  color: rgb(62, 62, 62);

  /* ~~~~ FOR DEBUGGING~~~~ */
  /* border: 2px solid rgb(37, 155, 228); */
}

.go-to-top:hover {
  border: rgb(113, 101, 33), solid;
  color: rgb(113, 101, 33);
  cursor: pointer;
  background-color: rgba(196, 106, 181, 0.377);
}

.footer {
  display: flex;
  flex-flow: row wrap;
  max-width: 100vw;
  height: 100%;
  padding-top: 0.5rem;
  border-top: 1px solid;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(255, 51, 0); */
}

.columns-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(0, 166, 255); */
}

.footer-right-column,
.footer-middle-column,
.footer-left-column {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 30%;
  height: 15rem;
  padding-top: 10px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 3px solid rgb(89, 0, 255); */
}

.footer-middle-column {
  padding-right: 5px;
}

.footer-item {
  color: white;
  width: 9rem;
  margin-top: 10px;
  margin-bottom: 25px;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 1px solid green; */
}

.attrib-footer {
  display: flex;
  flex-flow: row;
  font-size: 0.7rem;
  align-items: center;
  width: 100%;
  color: black;
  margin-bottom: 6%;
  padding-left: 10px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid rgba(66, 66, 66, 0.5);
  text-decoration: none;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 5px solid rgb(228, 37, 139); */
}

.rights-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;

  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 4px solid rgb(238, 142, 240); */
}

.rights-text {
  color: white;
  text-align: center;
  font-size: 0.9rem;
  /* ~~~~ FOR DEBUGGING~~~~*/
  /* border: 1px solid green; */
}

.whatsapp-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 85vh;
  width: 100%;
  z-index: 10;

  /* border: 2px solid red; */
}

.whatsapp-atr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(60, 143, 37);
  border-radius: 10%;
  z-index: 10;
  /* margin-right: 5%; */
}

.whatsapp-svg {
  width: 40px;
  height: 40px;
  justify-self: self-end;
  /* border: 1px solid red; */
}
