html, body {
  margin: 0 auto;
  padding: 0;
  font-family: Raleway, sans-serif;
  overflow-x: hidden;
}

body {
  max-width: 100%;
  height: auto;
}

header {
  flex-flow: column;
  width: 100%;
  height: auto;
  display: flex;
}

.hero-section {
  flex-flow: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.main-image {
  width: 100%;
  height: auto;
}

.logo {
  z-index: 0;
  width: 25%;
  margin-bottom: 2rem;
}

.modal, .modal-reveal {
  display: none;
}

h3 {
  font-variant: small-caps;
  color: #ffffffd5;
  width: 40%;
  height: 20%;
  font-size: clamp(1.2rem, 6vw, 4rem);
  font-weight: lighter;
  position: absolute;
  bottom: -3%;
  left: 55%;
}

h3 span {
  color: #5eb6ae;
  border-radius: 5px;
  padding: 5px;
  font-weight: 700;
}

.thanks, .hide {
  display: none;
}

p {
  font-size: 1.3rem;
}

.menu {
  z-index: 10;
  flex-flow: column;
  width: 165px;
  height: 305px;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: ease;
  display: flex;
  position: absolute;
  top: 10px;
  left: -200px;
}

.menu__ul {
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  max-width: 115%;
  height: 300px;
  display: flex;
  position: relative;
}

.main-nav-tab {
  background-color: #ffffffce;
  border-bottom: 1px solid;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  display: flex;
}

.contact {
  border-bottom: none;
}

.main-nav-tab:hover {
  cursor: pointer;
  background-color: #ffffffec;
}

.attrib {
  color: #000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.attrib-heb {
  font-family: Amatic SC, sans-serif;
  font-size: 1.4rem;
}

.categories-list {
  width: 165px;
  list-style: none;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: ease;
  position: absolute;
  top: 60px;
  left: -200px;
}

.category-item {
  background-color: #e1dcc1c7;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.category-item:hover {
  background-color: #caadb7cf;
}

.reveal {
  text-align: center;
  flex-flow: column;
  display: flex;
  left: 160px;
}

.category-text {
  justify-content: center;
  display: flex;
}

.icons-container {
  z-index: 10;
  background-color: #fff;
  flex-flow: row-reverse;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding-right: .8rem;
  display: flex;
}

.menubars-svg {
  background-color: #ffffff9d;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-left: 1.5rem;
  padding: 2px;
  display: flex;
}

.shoppingcart-container {
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  display: flex;
  position: relative;
}

.shoppingcart-mobile {
  fill: #000;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  padding: 1px;
  position: relative;
}

.cart-number {
  display: none;
}

.cart-number-mobile {
  text-align: center;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  font-size: .6rem;
  display: flex;
  position: absolute;
  top: 35%;
  left: 55%;
}

.toggle-svg {
  display: none;
}

#menubars-svg {
  fill: #000;
}

.shoppingcart-svg {
  display: none;
}

#login-tab {
  border-bottom: none;
}

#show-menu-button {
  display: none;
}

.languages-container {
  background-color: #ffffffce;
  border-top: 1px solid;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}

.languages-container button {
  background-color: #ffffffe6;
  border: none;
  border-radius: 5px;
  width: 2.5rem;
  height: 1.6rem;
  font-size: 1rem;
  box-shadow: 0 0 10px 5px #80808099;
}

main {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 25%;
}

.slider-container {
  width: 90%;
  margin-top: 9px;
}

.slider-wrapper .img-list .slider-image-item {
  object-fit: cover;
  height: 100%;
}

.slider-wrapper {
  flex-flow: row;
  justify-content: center;
  display: flex;
  overflow-x: hidden;
}

.img-list {
  flex-flow: row;
  width: 100%;
  display: flex;
  position: relative;
}

.slider-image-item {
  width: 100%;
  padding: 5px;
  transition: transform 1s;
}

.slider_btns {
  cursor: pointer;
  color: #6c6c6c;
  text-align: center;
  background-color: #fffada99;
  border: .5px solid #c6aa767a;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 8%;
  height: 8%;
  font-size: 2rem;
  display: flex;
  position: absolute;
  top: 2%;
}

.slider-btn--left {
  left: 8%;
}

.slider-btn--right {
  right: 8%;
}

.slider_btns:hover {
  background-color: #e8e8e899;
}

.categories-container {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
  margin-top: 50px;
  padding-top: 2rem;
  display: flex;
  box-shadow: 0 0 50px -10px #00000094;
}

.category-box {
  width: 90%;
  height: 20%;
  margin-bottom: 2.5rem;
  text-decoration: none;
  position: relative;
}

.large-img-item {
  width: 100%;
  height: auto;
}

.category-name {
  color: #000;
  background-color: #ffffffe2;
  padding: 5px;
  font-size: clamp(2rem, 2vh, 2rem);
  position: absolute;
  bottom: 10%;
  left: 5%;
}

.large-img-item.hidden {
  opacity: 0;
  width: 100%;
  margin: 10px;
}

.go-to-top {
  color: #3e3e3e;
  background-color: #000;
  border: none;
  border-radius: 50%;
  align-self: center;
  width: 3rem;
  height: 3rem;
  margin: 4rem;
  padding: 5px;
  font-size: 2rem;
  position: relative;
}

.go-to-top:hover {
  border: #716521, solid;
  color: #716521;
  cursor: pointer;
  background-color: #c46ab560;
}

.footer {
  border-top: 1px solid;
  flex-flow: wrap;
  max-width: 100vw;
  height: 100%;
  padding-top: .5rem;
  display: flex;
}

.columns-container {
  justify-content: space-between;
  width: 100%;
  height: 10%;
  display: flex;
}

.footer-right-column, .footer-middle-column, .footer-left-column {
  flex-flow: column wrap;
  align-items: center;
  width: 30%;
  height: 15rem;
  padding-top: 10px;
  display: flex;
}

.footer-middle-column {
  padding-right: 5px;
}

.footer-item {
  color: #fff;
  width: 9rem;
  margin-top: 10px;
  margin-bottom: 25px;
}

.attrib-footer {
  color: #000;
  border-bottom: .5px solid #42424280;
  flex-flow: row;
  align-items: center;
  width: 100%;
  margin-bottom: 6%;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: .7rem;
  text-decoration: none;
  display: flex;
}

.rights-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
}

.rights-text {
  color: #fff;
  text-align: center;
  font-size: .9rem;
}

.whatsapp-container {
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 85vh;
}

.whatsapp-atr {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #3c8f25;
  border-radius: 10%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.whatsapp-svg {
  justify-self: self-end;
  width: 40px;
  height: 40px;
}
/*# sourceMappingURL=index.56e065db.css.map */
